import { Link as RouterLink, LinkProps as RouterLinkProps } from '@mui/material';

interface LinkProps extends RouterLinkProps {
  to: string;
  replace?: boolean;
}

export default function Link(linkProps: LinkProps) {
  let {to, color, ...propsWithoutTo} = linkProps;
  return <RouterLink {...propsWithoutTo} href={`${to.startsWith('#') ? '' : '#'}${to}`} color={`${!!color ? color : "inherit"}`} component={RouterLink as any} />;
}

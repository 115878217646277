import DataGrid, { Export, GroupPanel, Grouping, Pager, Paging, Sorting, FilterRow, HeaderFilter, SearchPanel,  FilterPanel,
  FilterBuilderPopup, ColumnChooser, ColumnFixing } from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { exportDataGrid as pdfExportDataGrid } from 'devextreme/pdf_exporter';
import { saveAs } from 'file-saver-es';
import { jsPDF } from 'jspdf';
import filenamify from '../../utils/filenamify';
import CustomStore from 'devextreme/data/custom_store';

function onXLSExporting(e: any, fileName: string) {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('Foglio1');

  exportDataGrid({
    component: e.component,
    worksheet,
    autoFilterEnabled: true,
  }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
    });
  });
  e.cancel = true;
}

function onPDFExporting(e: any, fileName: string) {
  const doc = new jsPDF();

  pdfExportDataGrid({
    jsPDFDocument: doc,
    component: e.component,
    indent: 5,
  }).then(() => {
    doc.save(`${fileName}.pdf`);
  });
}

function getFetch(url: string, loadOptions: any) {
  let params = '?';
  [
    'skip',
    'take',
    'requireTotalCount',
    'requireGroupCount',
    'sort',
    'filter',
    'totalSummary',
    'group',
    'groupSummary',
  ].forEach((i) => {
    if (i in loadOptions && isNotEmpty(loadOptions[i])) { params += `${i}=${JSON.stringify(loadOptions[i])}&`; }
  });
  params = params.slice(0, -1);
  return fetch(`${url}${params}`).then((response) => response.json())
}

function isNotEmpty(value: any) {
  return value !== undefined && value !== null && value !== '';
}

function getDataSource(dataSource: any, apiUrl: string, apiRequest: any)  {
  if (!apiUrl && !apiRequest) { return dataSource }

  return new CustomStore({
    load(loadOptions) {
      const request = (!!apiRequest ? apiRequest : getFetch(apiUrl, loadOptions))
      return request
        .then((data: any) => {
          return {
            data: data.data,
            totalCount: data.totalCount,
            summary: data.summary,
            groupCount: data.groupCount,
          }
        })
        .catch(() => { throw new Error('Data Loading Error'); });
    },
  });
}

export default function Grid(props: any) {
  const { dataSource, apiUrl, apiRequest, fileName } = props;

  const onExporting = (e: any) => {
    const {format} = e
    if (format === 'pdf') {
      return onPDFExporting(e, filenamify(fileName))
    }

    onXLSExporting(e, filenamify(fileName))
  }

  return (
    <DataGrid
            dataSource={getDataSource(dataSource, apiUrl, apiRequest)}
            showBorders={true}
            allowColumnReordering={true}
            rowAlternationEnabled={true}
            columnHidingEnabled={true}
            rtlEnabled={false}
            onExporting={onExporting}
            allowColumnResizing={true}
            columnAutoWidth={true}
            >
              <Sorting mode="multiple" />
              <GroupPanel visible={true} />
              <Grouping autoExpandAll={false} />
              <Export enabled={true} formats={['pdf', 'xls']} />
              <Paging defaultPageSize={10} />
              <Pager allowedPageSizes={[10, 25, 50, 100]} showPageSizeSelector={true} />
              <FilterRow visible={true} />
              <HeaderFilter visible={true} />
              <SearchPanel visible={true} highlightCaseSensitive={false} />
              <FilterPanel visible={false} />
              <FilterBuilderPopup position={{ of: window, at: 'top', my: 'top', offset: { y: 10 }}} />
              <ColumnChooser enabled={true} />
              <ColumnFixing enabled={true} />
              {props.children}
          </DataGrid>
  );
}

import { HomePage, TasksPage, ProfilePage, UsersPage, QueriesPage, QueryItemPage } from './pages';
import { withNavigationWatcher } from './contexts/navigation';

const routes = [
    {
        path: '/tasks',
        element: TasksPage
    },{
      path: '/queries',
      element: QueriesPage
    },{
      path: '/queries/:id',
      element: QueryItemPage
    },{
      path: '/users',
      element: UsersPage
    },
    {
        path: '/profile',
        element: ProfilePage
    },
    {
        path: '/home',
        element: HomePage
    }
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});

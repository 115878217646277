import React from 'react';
const deepEqual = require('deep-equal');

export default class LoadComponent extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      showData: false,
      data: null
    };

    this.loadData = this.loadData.bind(this);
  }

  loadRemoteData(): Promise<any> {
    return Promise.resolve();
  }

  setisLoadingData(isLoadingData: boolean) {
    this.setState({ loading: isLoadingData});
  }

  loadData() {
    this.setState({
      showData: true,
      loading: true,
    });
    this.loadRemoteData()
      .then((json) => {
        this.setState({ loading: false, data: json });
      })
  }

  componentDidMount() {
    this.loadData()
  }

  componentDidUpdate() {
  }

  shouldComponentUpdate(nextProps: any, nextState: any) {
    return !deepEqual(this.state, nextState)
  }

  isLoading(): boolean {
    return !this.state.showData || this.state.loading
  }

  public render() {
    return (<></>)
  }
}

const {default: trimRepeated} = require('trim-repeated')
const filenameReservedRegexModule = require('filename-reserved-regex')
const {default: filenameReservedRegex, windowsReservedNameRegex} = filenameReservedRegexModule
const {default: stripOuter} = require('strip-outer')

// Doesn't make sense to have longer filenames
const MAX_FILENAME_LENGTH = 100;

const reControlChars = /[\u0000-\u001F\u0080-\u009F]/g; // eslint-disable-line no-control-regex
const reRelativePath = /^\.+(\\|\/)|^\.+$/;
const reTrailingPeriods = /\.+$/;

export interface Options {
	/**
	String to use as replacement for reserved filename characters.

	Cannot contain: `<` `>` `:` `"` `/` `\` `|` `?` `*`

	@default '!'
	*/
	readonly replacement?: string;

	/**
	Truncate the filename to the given length.

	Only the base of the filename is truncated, preserving the extension. If the extension itself is longer than `maxLength`, you will get a string that is longer than `maxLength`, so you need to check for that if you allow arbitrary extensions.

	Systems generally allow up to 255 characters, but we default to 100 for usability reasons.

	@default 100
	*/
	readonly maxLength?: number;
}

/**
Convert a string to a valid filename.

@example
```
import filenamify from 'filenamify';

filenamify('<foo/bar>');
//=> 'foo!bar'

filenamify('foo:"bar"', {replacement: '🐴'});
//=> 'foo🐴bar'
```
*/
export default function filenamify(string: string, options: Options = {}): string {
  if (typeof string !== 'string') {
		throw new TypeError('Expected a string');
	}

	const replacement = options.replacement === undefined ? '!' : options.replacement;

	if (filenameReservedRegex().test(replacement) && reControlChars.test(replacement)) {
		throw new Error('Replacement string cannot contain reserved filename characters');
	}

	string = string.normalize('NFD');
	string = string.replace(reRelativePath, replacement);
	string = string.replace(filenameReservedRegex(), replacement);
	string = string.replace(reControlChars, replacement);
	string = string.replace(reTrailingPeriods, '');

	if (replacement.length > 0) {
		const startedWithDot = string[0] === '.';

		string = trimRepeated(string, replacement);
		string = string.length > 1 ? stripOuter(string, replacement) : string;

		// We removed the whole filename
		if (!startedWithDot && string[0] === '.') {
			string = replacement + string;
		}

		// We removed the whole extension
		if (string[string.length - 1] === '.') {
			string += replacement;
		}
	}

	string = windowsReservedNameRegex().test(string) ? string + replacement : string;
	const allowedLength = typeof options.maxLength === 'number' ? options.maxLength : MAX_FILENAME_LENGTH;
	if (string.length > allowedLength) {
		const extensionIndex = string.lastIndexOf('.');
		if (extensionIndex === -1) {
			string = string.slice(0, allowedLength);
		} else {
			const filename = string.slice(0, extensionIndex);
			const extension = string.slice(extensionIndex);
			string = filename.slice(0, Math.max(1, allowedLength - extension.length)) + extension;
		}
	}

	return string;
}

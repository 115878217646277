import React from 'react';
import { Column } from 'devextreme-react/data-grid';
import { Api } from '../../api/api';
import Link from '../../components/link/Link';
import Grid from '../../components/grid/Grid';
const deepEqual = require('deep-equal');

export default class Query extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.cellRender = this.cellRender.bind(this);
  }

  cellRender(data: any) {
    return <Link to={`/queries/${data.data.id}`}>{data.value}</Link>
  }

  shouldComponentUpdate(nextProps: any, nextState: any) {
    return !deepEqual(this.state, nextState)
  }

  public render() {
    return (
      <React.Fragment>
        <h2 className={'content-block'}>Queries</h2>
        <Grid apiRequest={Api.queries().then((rows) => {
          return {data: rows}
        })} fileName="Queries">
            <Column dataField="name" cellRender={this.cellRender} />
        </Grid>
      </React.Fragment>
    )
  }
}

import React from 'react';
import 'devextreme/data/odata/store';
import DataGrid from 'devextreme-react/data-grid';
import { customers } from './data';

export default class Task extends React.Component {
  public render() {
    return (
      <React.Fragment>
        <h2 className={'content-block'}>Tasks</h2>
        <DataGrid
          dataSource={customers}
          keyExpr="ID"
          showBorders={true}
        />
      </React.Fragment>
    )
  }
}

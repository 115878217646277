import Typography from '@mui/material/Typography';
import MaterialBreadcrumbs from '@mui/material/Breadcrumbs';
import { useLocation } from 'react-router-dom';
import { useMatch } from "react-router-dom";
import Link from '../link/Link';

const breadcrumbNameMap: { [key: string]: string } = {
  '/queries': 'Queries',
  '/queries/:id': 'Query'
};

export default function Breadcrumbs() {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);
  const routeMatches = useMatch('/queries/:id');

  return (
    <MaterialBreadcrumbs aria-label="breadcrumb">
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;
        let name = breadcrumbNameMap[to];

        if (!name) {
          if (!routeMatches) {return <div></div>}
          const params: any = routeMatches.params
          if (!params) {return <div></div>}
          const keys: string[] = Object.keys(params)
          if (keys.length === 0) {return <div></div>}
          const key = keys[0];
          name = `${breadcrumbNameMap[`/${pathnames.slice(0, index).join('/')}/:${key}`]} ${params[key]}`
        }

        return last ? (
          <Typography color="text.primary" key={to}>
            {name}
          </Typography>
        ) : (
          <Link underline="hover" color="inherit" to={to} key={to}>
            {name}
          </Link>
        );
      })}
    </MaterialBreadcrumbs>
  );
}

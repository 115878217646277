import React from 'react';
import './profile.scss';
import Form, {ButtonItem, Item } from 'devextreme-react/form';
import LoadComponent from '../../components/load-component/load-component';
import { Api } from '../../api/api';
import LoadContent from '../../components/load-content/LoadContent';

export default class Profile extends LoadComponent {
  constructor(props: any) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  loadRemoteData() {
    return Api.me()
  }

  handleSubmit(e: any) {
    e.preventDefault();
    console.log("handleSubmit")
    console.log(this.state.data)
  }

  public render() {
    return (
      <React.Fragment>
        <LoadContent isLoadingData={this.state.isLoadingData} setisLoadingData={this.setisLoadingData}>
          <h2 className={'content-block'}>Profile</h2>
          <div className={'content-block dx-card responsive-paddings'}>
            <form action="your-action" onSubmit={this.handleSubmit}>
            <Form
              id={'form'}
              formData={this.state.data}
              labelLocation={'top'}
              colCount={2}
            >
              <Item dataField="name"/>
              <Item dataField="surname"/>
              <ButtonItem horizontalAlignment="left" buttonOptions={{
                text: 'Salva',
                type: 'success',
                useSubmitBehavior: true,
              }}/>
            </Form>
            </form>
          </div>
        </LoadContent>
      </React.Fragment>
    );
  }
}

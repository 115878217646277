import { User } from '../types';
import defaultUser from '../utils/default-user';
import { Api } from './api';

export async function signIn(email: string, password: string) {
  try {
    const {token, refresh_token}  = await Api.login(email, password);
    localStorage.setItem('token', token);
    localStorage.setItem('refresh_token', refresh_token);
    const me = await Api.me();
    const user = {email: me.email, avatarUrl: me.photo_url} as User
    return {
      isOk: true,
      data: user
    };
  }
  catch {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    return {
      isOk: false,
      message: "Authentication failed"
    };
  }
}

export async function getUser() {
  try {
    const {email, photo_url} = await Api.me();
    const user = {email, avatarUrl: photo_url} as User
    return {
      isOk: true,
      data: user
    };
  }
  catch {
    return {
      isOk: false
    };
  }
}

export async function createAccount(email: string, password: string) {
  try {
    // Send request
    console.log(email, password);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to create account"
    };
  }
}

export async function changePassword(email: string, recoveryCode?: string) {
  try {
    // Send request
    console.log(email, recoveryCode);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to change password"
    }
  }
}

export async function resetPassword(email: string) {
  try {
    // Send request
    console.log(email);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to reset password"
    };
  }
}

import { Skeleton, Typography } from '@mui/material';
import React from 'react';
import { Api } from '../../api/api';
import Grid from '../../components/grid/Grid';
import LoadComponent from '../../components/load-component/load-component';

export default class QueryItem extends LoadComponent {
  constructor(props: any) {
    super(props);
  }

  loadRemoteData(): Promise<any> {
    return Api.query(this.props.params.id)
  }

  public render() {
    return (
      <React.Fragment>
        {
          (this.isLoading()) ?
            (<>
              <div className={'content-block'}>
                <Typography component="div" key={'h2'} variant={'h2'}>
                  {true ? <Skeleton width={200} /> : 'h2'}
                </Typography>
              </div>
              <Skeleton variant="rectangular" width={'100%'} height={200} />
            </>) : (<>
              <h2 className={'content-block'}>{this.state.data.name}</h2>
              <Grid apiRequest={Api.queryData(this.props.params.id)} fileName={this.state.data.name} />
            </>)
        }
      </React.Fragment>
    )
  }
}

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCNNZ_SLJK6NkiZ2Udea0pwlke7Udt9i54",
  authDomain: "blazer-71393.firebaseapp.com",
  projectId: "blazer-71393",
  storageBucket: "blazer-71393.appspot.com",
  messagingSenderId: "693784908457",
  appId: "1:693784908457:web:4095a41eeed555493a0fb8"
};
const app = initializeApp(firebaseConfig);

export class Api {
  private static url = "https://us-central1-blazer-71393.cloudfunctions.net/api"

  private static getToken() {
    return localStorage.getItem('token')
  }

  static async login(email: string, password:string) {
    const token = await this.fbIdTokenByCredential(email, password)
    return this.postData(`${this.url}/auth/login/token`, { token })
      .catch((error) => console.log(error));
  }

  static me() {
    return fetch(`${this.url}/user/me`, {
      "headers": {
        "Authorization": `Bearer ${this.getToken()}`
      }
    })
      .then((response) => response.json())
      .catch((error) => console.log(error));
  }

  static users() {
    return fetch(`${this.url}/user`, {
      "headers": {
        "Authorization": `Bearer ${this.getToken()}`
      }
    })
      .then((response) => response.json())
      .catch((error) => console.log(error));
  }

  static queries() {
    return fetch(`${this.url}/query`, {
      "headers": {
        "Authorization": `Bearer ${this.getToken()}`
      }
    })
      .then((response) => response.json())
      .catch((error) => console.log(error));
  }

  static query(id: string) {
     return fetch(`${this.url}/query/${id}`, {
      "headers": {
        "Authorization": `Bearer ${this.getToken()}`
      }
    })
      .then((response) => response.json())
      .catch((error) => console.log(error));
  }

  static queryData(id: string) {
    return fetch(`${this.url}/query/${id}/data`, {
     "headers": {
       "Authorization": `Bearer ${this.getToken()}`
     }
   })
     .then((response) => response.json())
     .catch((error) => console.log(error));
 }

  static async postData(url = '', data = {}) {
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((response) => response.json())
  }
  
  private static async fbIdTokenByCredential(email: string, password: string): Promise<string> {
    const auth = getAuth();
    const userCredential = await signInWithEmailAndPassword(auth, email, password)
    return userCredential.user.getIdToken()
  }
}
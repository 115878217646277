import React from 'react';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import { Api } from '../../api/api';
import './users.scss';
import PlaceholderUserDark from "../../../src/assets/img/placeholder_user_dark.png";
import LoadContent from '../../components/load-content/LoadContent';
import LoadComponent from '../../components/load-component/load-component';

export default class User extends LoadComponent {
  constructor(props: any) {
    super(props);
    this.cellRender = this.cellRender.bind(this);
  }

  loadRemoteData() {
    return Api.users()
  }

  cellRender(data: any) {
    const value = data.value;
    return <div className="image-cropper-user-list">
      {!!value ? (<img
        className="img-fluid img-circle-user-list"
        src={value}
        alt="user list image" />) : <img className="img-fluid img-circle-user-list" src={PlaceholderUserDark} alt="user list image" />}
    </div>;
  }

  public render() {
    return (
      <React.Fragment>
        <LoadContent isLoadingData={this.state.isLoadingData} setisLoadingData={this.setisLoadingData}>
          <h2 className={'content-block'}>Users</h2>
          {this.state.showData && !this.state.isLoadingData ? (<DataGrid
            dataSource={this.state.data}
            keyExpr="id"
            showBorders={true}
            >
            <Column dataField="enabled" width={100} alignment={"center"}/>
            <Column dataField="photo_url" width={100} cellRender={this.cellRender} />
            <Column dataField="role" width={150}/>
            <Column dataField="email"/>
            <Column dataField="name"/>
            <Column dataField="surname"/>
            <Column dataField="mobile"/>
          </DataGrid>) : <></>}
        </LoadContent>
      </React.Fragment>
    )
  }
}

import React from 'react';
import {LoadPanel} from 'devextreme-react/load-panel';

export default class LoadContent extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.hideLoadPanel = this.hideLoadPanel.bind(this);
  }

  hideLoadPanel() {
    this.props.setisLoadingData(false);
  }

  public render() {
    return (
      <>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={{of: '.content'}}
          onHiding={this.hideLoadPanel}
          visible={this.props.isLoadingData}
          showIndicator={true}
          shading={true}
          showPane={true}
        />
        {this.props.children}
      </>
    );
  }
}
